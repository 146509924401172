window.wistiaInitQueue = window.wistiaInitQueue || [];

const withVisitorKey = (callback) => {
  window.wistiaInitQueue.push((wistia) => {
    Wistia.visitorKey.ready().then(() => {
      callback(wistia.visitorKey.value());
    });
  });
};

// Registration form contact_visitor_key
const wistiaVisitorKeyField = document.querySelector('#user_contact_visitor_key');

if (wistiaVisitorKeyField) {
  withVisitorKey((visitorKey) => {
    if (visitorKey) {
      wistiaVisitorKeyField.value = visitorKey;
    }
  });
}

// OAuthButtons
const googleLink = document.querySelector('#google_oauth');
const microsoftLink = document.querySelector('#microsoft_oauth');

const { standalone } = window.navigator;
const userAgent = window.navigator.userAgent.toLowerCase();
// This check includes chromuim browsers because they still contain "Safari" in the UA
const includesSafari = /safari/.test(userAgent);
const ios = /iphone|ipod|ipad/.test(userAgent);

if (ios) {
  if (!standalone && !includesSafari) {
    googleLink.style.display = 'none';
  }
} else if (userAgent.includes('wv')) {
  googleLink.style.display = 'none';
} else {
  // no-op
}

withVisitorKey((visitorKey) => {
  [googleLink, microsoftLink]
    .filter((link) => link)
    .forEach((link) => {
      const url = new URL(link.href);
      url.searchParams.append('contact_visitor_key', visitorKey);
      // eslint-disable-next-line no-param-reassign
      link.href = url.href;
    });
});
